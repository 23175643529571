@import '/styles/colors';

.customBullet {
  background: white !important;
  opacity: 1;
}

.customBulletActive {
  background: $primary-05 !important;
  opacity: 1;
}

.customCarousel {
  height: inherit;
}

.customCarouselItem {
  height: inherit;
}