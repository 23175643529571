@import '/styles/colors';
@import '/styles/mixins';
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 88px;
  background-color: $primary-05;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1366px;
  width: calc(100% - 20px);
  @include body-16;
}

.menuItems {
  display: contents;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.menuItemsMobile {
  padding: 0px 25px;
}

.menuItemsMobile > * + * {
  margin-top: 16px;
}

.item {
  color: $white-05;
  font-size: 16px;
  border: none;
  justify-content: center;
  min-width: 109px;
  height: 48px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  img {
    margin-left: 10px;
  }

  &:hover {
    color: $white-05;
    background-color: $primary-06;
    transition: background-color 0.2s ease-in-out;
    border-radius: 4px;
  }
}

.itemActive {
  color: $white-05;
  background-color: $primary-06;
  border-radius: 4px;
}

.header a:link,
.header a:visited,
.header a:hover,
.header a:active {
  text-decoration: none;
}

.contentMobile {
  display: none;
}

@media (max-width: 1200px) {
  .content {
    display: none;
  }

  .contentMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
  }

  .header {
    padding: 15px 0;
  }
}

.menuOpener {
  cursor: pointer;
  border-radius: 8px;
}

.drawer {
  position: fixed;
  height: 100vh;
  width: 300px;
  margin-right: -300px;
  right: 0;
  top: 0;
  background-color: white;
  transition: margin-right 0.3s ease-in-out;
  overflow-y: auto;
}

.drawerOpened {
  margin-right: 0px;
}

.drawerBackgroundVisible {
  z-index: 10000001;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease-in-out;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.drawerBackground {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-in-out;
}

.drawerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $primary-05;

  img {
    padding: 8px;
  }
}

.itemMobile {
  color: $primary-04;
  font-size: 14px;
  padding-top: 16px;
}

.itemMobileActive {
  font-weight: bold;
}

.copyStyleButtonMenu {
  border-radius: 0.375rem;
  font-weight: 700;
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.2;
  outline: none;
  height: 3.25rem;
  min-width: 2.5rem;
  font-size: 1.25rem;
  padding: 0.75rem 2rem;
  background-color: $orange-05;
  color: $white-05;
}
